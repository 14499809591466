<template>
  <v-app id="inspire">
    <v-navigation-drawer
        v-if="!['login', 'forgot_password', 'validation'].includes($route.name)"
        app
        color="other"
        width="60px"
    >
      <v-avatar
        class="d-block text-center mx-auto my-4"
      >
        <a href="/">
          <v-img
            class="logo"
            width="41px"
            src="@/assets/logo.png"
          >
          </v-img>
        </a>
      </v-avatar>
      <div 
        id="menu"
        class="d-flex-column"
      >
        <v-tooltip right color="tvf_color">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="icons ml-3 my-2"
              depressed
              icon
              tile
              v-bind="attrs"
              v-on="on"
              to="/"
            >
              <v-icon
                dark
                color="black"
              >
                mdi-home-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Dashboard</span>
        </v-tooltip>    
        <v-tooltip right color="tvf_color">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="icons ml-3 my-2"
              depressed
              icon
              tile
              v-bind="attrs"
              v-on="on"
              to="/management"
            >
              <v-icon
                dark
                color="black"
              >
                mdi-database
              </v-icon>
            </v-btn>
          </template>
          <span>Management</span>
        </v-tooltip> 
        <v-tooltip right color="tvf_color">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="icons ml-3 my-2"
              depressed
              icon
              tile
              v-bind="attrs"
              v-on="on"
              to="/campaigns"
            >
              <v-icon
                dark
                color="black"
              >
                mdi-email-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Campaigns</span>
        </v-tooltip>  
        <v-tooltip right color="tvf_color">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="icons ml-3 my-2"
              depressed
              icon
              tile
              v-bind="attrs"
              v-on="on"
              to="/invoicing"
            >
              <v-icon
                dark
                color="black"
              >
                mdi-currency-usd
              </v-icon>
            </v-btn>
          </template>
          <span>Invoicing</span>
        </v-tooltip>  
        <v-spacer class="space"></v-spacer>      
        <v-btn
          id="notification"
          class="ml-3"
          dark
          icon
          depressed
          tile
        >
          <v-icon
            dark
            color="#A3A3A3"
          >
            mdi-bell-outline
          </v-icon>
        </v-btn>
        <v-menu
          top
          :offset-x="offset"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="account"
              class="ml-3 my-2"
              dark
              icon
              depressed
              tile
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                dark
                color="white"
              >
                mdi-account-outline
              </v-icon>
            </v-btn>
          </template>

          <v-card width="200px">
            <v-list>
              <v-list-item @click="profile()">
                <div>
                  <label class="caption font-weight-medium">Profile settings</label>
                  <v-list-item-title class="caption font-weight-bold mt-2">View Profile</v-list-item-title>
                </div>
              </v-list-item>
            </v-list>

            <v-divider class="mx-2"></v-divider>
            <v-spacer class="py-12"></v-spacer>
            <v-divider class="mx-2"></v-divider>
            <v-list>
              <v-list-item @click="logout()">
                <v-list-item-title class="caption font-weight-bold">Log Out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>       
      </div>
    </v-navigation-drawer>

    <v-main>
<!--         <Management v-if="!['login','forgot_password','dashboard','invoicing','campaigns', 'list', 'calendar', 'stats', 'lead-results', 'sendouts'].includes($route.name)" />
 --><!--         <Campaign v-if="['campaigns','list', 'calendar', 'stats', 'lead-results', 'sendouts'].includes($route.name)" @updateSearch="searchText = $event" />
 -->        <v-snackbar
          v-for="(snackbar, index) in snackbars.filter(s => s.showing)"
          :key="snackbar.text + Math.random()"
          :timeout="snackbar.timeout"
          class="msg"
          top
          right
          v-model="snackbar.showing"
          :color="snackbar.color"
          :style="`top: ${(index*70) + 8}px`"
        >
          {{snackbar.text}}
          <v-btn
            text
            class="ml-4"
            color="tvf_color"
            @click="snackbar.showing = false"
          >
            Close
          </v-btn>
        </v-snackbar>
        <v-container>
          <router-view></router-view>
        </v-container>
    </v-main>
    <Footer v-if="!['login', 'forgot_password'].includes($route.name)"/>
  </v-app>
</template>

<script>
import Navbar from "@/components/core/Navbar";
import Footer from "@/components/core/Footer";
/* import Management from "@/components/core/ManagementNav";
import Campaign from "@/components/core/CampaignNav"; */
import { mapState, mapActions } from "vuex";
import store from "./store";
import { TokenService } from './services/storage.service';
import '@progress/kendo-theme-default/dist/all.css';

export default {
    components: {
    Navbar,
    Footer,
/*     Management,
    Campaign */
  },
  data: () => ({
    searchText: '',
    offset: true,
    links: [
      {
        name: "Dashboard",
        to: '/'
      },
      {
        name: "Management",
        to: '/management'
      },
      {
        name: "Campaigns",
        to: '/campaigns'
      },
      {
        name: "Invoicing",
        to: '/invoicing'
      }
    ],
    notifications: [
      "Mike John Responded to your email",
      "You have 5 new tasks",
    ],
    drawer: false
  }),

  computed: {
    ...mapState(["user", "snackbars"]),
    ...mapActions(['LogOut']),

    userLogged() {
      const data = store.state.auth.user;
      if(data){
        return data.first_name;
      }
      
    },

    isLoggedIn: function() {
      return !!TokenService.getToken()
    },

  },

  mounted() {
    if (!this.userLogged) {
      this.$router.push('/login').catch(()=>{});
    }
  },

  methods:  {

    profile(){
      this.$router.push('/profile').catch(()=>{});        
    },

    logout(){
      store.dispatch("LogOut");
      this.$router.push("/login").catch(()=>{});
    }

  },
};
</script>

<style>

.k-stepper .k-step-current:hover .k-step-indicator, .k-stepper .k-step-current.k-hover .k-step-indicator, .k-stepper .k-step-current.k-step-hover .k-step-indicator {
    background-color: white;
    border-radius: 10%;
    border-color: white;
}

.k-stepper .k-step-label .k-step-text {
    color: white;
    font-size: 15px;
    line-height: 19px;
    font-style: normal;
}

.k-stepper .k-step-done:hover .k-step-indicator {
  background-color: white;
  border-color: white;
  color: black;
}

.k-stepper .k-step-current .k-step-indicator::after {
  border-radius: 10%;
}

.k-stepper .k-step-current .k-step-indicator:hover {
  border-color: white;
}

.k-stepper .k-step-current .k-step-indicator {
  color: black;
  background-color: white;
  border-color: white;
}

.k-stepper .k-step-indicator, .k-stepper .k-step-done .k-step-indicator  {
  border-radius: 10% !important;
  color: white;
  width: 35px;
  height: 35px;
  background-color: #8A0F48;
  border-color: #8A0F48;
}

/* @font-face {
  font-family: "Circular";
  src: url("~@/assets/fonts/CircularStd-Book.otf") format("otf"),
  url("~@/assets/fonts/CircularStd-Light.otf") format("otf"),
  url("~@/assets/fonts/CircularStd-Medium.otf") format("otf");

} */

.container {
  padding: 0px !important;
}

* {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.space {
  margin-top: 150px;
}

.theme--light.v-application {
  background: #F5F5F5 !important; 
}

#account {
  background-color: #F85182 !important;
  border-radius: 5px;
}

#notification {
  background-color:  #F8F9FB !important;
  border-radius: 5px;
}

.icons::before {
  border-radius: 5px;
  background-color: black;
}

.v-btn--tile{
  border-radius: 2 !important;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: #FFFFFF !important;
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0px 1px 8px -11px rgb(50 50 50 / 75%), inset 0px 0px 8px -6px rgb(50 50 50 / 59%) !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 0.8rem !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th > span {
  font-weight: 600 !important;
}

.theme--light.v-tabs .v-tab:focus {
  opacity: 0 !important;
}

.v-snack__wrapper .v-snack__content{
    color: black !important;
}

.v-pagination {
  justify-content: start !important;
}

@media (min-width: 500px) {
  .v-application .ml-16 {
    margin-left: 25px !important;
  }
  .theme--light.v-data-table tbody tr.v-data-table__selected {
    background: none !important;
  }
}

@media (min-width: 1264px) {
  .container {
    width: 95%; /* Ajusta este valor según tus necesidades */
    max-width: 95%;
  }
}

@media (min-width: 1800px) {
  .container {
    width: 95%; /* Ajusta este valor según tus necesidades */
    max-width: 95%;
  }
}

</style>