<template>
  <div>
    <v-footer
      id="dashboard-core-footer"
    >
      <v-container>
        <v-row
          align="center"
          no-gutters
        >
          <v-col
            class="d-flex justify-end"
            cols="12"
          >
            <div class="pt-6 pt-md-0 text-center text-decoration-none">
              &copy; {{ year }}, <a href="https://thevaluefactory.es/">TheValueFactory</a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
  return {
      year: new Date().getFullYear(),
    };
  },
}
</script>

<style scoped>

.text-decoration-none{
    font-size: 12px;
    color: #313131;
}

.text-decoration-none a {
    text-decoration: none;
    color: #313131;
}
</style>