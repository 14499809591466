<template>
  <v-tabs>
    <v-tab
      id="link"
      class="px-6 d-none d-sm-flex"
      v-for="link in links"
      :key="link.name"
      :to="link.to"
    >
      {{ link.name }}
    </v-tab>
  </v-tabs>
</template>
<script>

export default {
  data: () => ({
      links: [
          {
          name: "Dashboard",
          to: '/',
          show: "create_users"
          },
          {
          name: "Management",
          to: '/management',
          show: "create_users"
          },
          {
          name: "Campaigns",
          to: '/campaigns',
          show: "create_users"
          },
          {
          name: "Invoicing",
          to: '/invoicing',
          show: "create_users"
          }
      ]
    })
}
</script>

<style scoped>

.v-tab--active{
  color: white !important;
}

</style>