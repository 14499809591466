import Vue from 'vue'
import Vuex from 'vuex'
import ApiService from "../services/api.service";
import TokenService from "../services/storage.service";
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth
  },

  plugins: [createPersistedState({
    paths: ['auth'],
    filter: (mutation) => mutation.type !== 'setLogoutState', // no guardar en estado la mutación 'setLogoutState'
  })],

  state: {
    search: '',
    users: [],
    databases: [],
    companies: [],
    owners: [],
    sources: [],
    roles: [],
    domains: [],
    platforms: [],
    countries: [],
    countriesByUser: [],
    allDatabases: [],
    usersByCountry: [],
    ownersByCountry: [],
    databasesByCountry: [],
    tagsByCountry: [],
    platformsByCountry:[],
    agenciesByCountry: [],
    sendersByCountry: [],
    targetsByCountry: [],
    templatesByCountry: [],
    agencies: [],
    sendouts:[],
    seeds: [],
    list: [],
    tags: [],
    paymodels: [],
    tvfPrefills: [],
    currencies: [],
    stats_filters: [],
    headers_items: [],
    footers_items: [],
    stats: [],
    statsGroup: [],
    newUser: {},
    filters:{},
    loading: false,
    loading2: false,
    addDialog: false,
    errors: [],
    snackbars: [],
    accessToken: null,
    status: false,
  },
  mutations: {

    getUsers(state) {
      state.loading = true 

      ApiService.get("users/list")
        .then((response) => {

          state.loading = false
          const users = response.data.data 
          state.users = users.sort(function (a, b) {
            if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
              return 1;
            }
            if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });    
          state.users.forEach((element, index) => {
            if(element.deleted_at !== null) {
              state.users[index].status = false
            } else {
              state.users[index].status  = true
            }
          });  
        })
        .catch((error) => {
          state.loading = false
          console.log("Ups! Something is wrong");
        });
    },

    getSources(state) {
      ApiService.get("sources/list")
      .then((response) => {
        const sources = response.data.data;  
        state.sources = sources.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
        Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    getPlatforms(state) {
      ApiService.get("platforms/list")
      .then((response) => {
        const platforms = response.data.data 
        state.platforms = platforms.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });    
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
        Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    getTags(state){
      ApiService.get("tags/list")
      .then((response) => {
        const tags = response.data.data 
        state.tags = tags.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });    
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
        Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    getPaymodels(state) {
      ApiService.get("paymodels/list")
      .then((response) => {
        const paymodels = response.data.data 
        state.paymodels = paymodels.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });    
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
        Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    getCountries(state) {
      ApiService.get("countries/list")
      .then((response) => {
        const countries = response.data.data 
        state.countries = countries.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });    
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
        Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    setList(state, list) {
      state.list = list;
    },
    setAgency(state, agencies) {
      state.agencies = agencies;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLoading2(state, loading) {
      state.loading2 = loading;
    },
    setSendout(state, sendouts) {
      state.sendouts = sendouts;
    },
    setStats(state, stats) {
      state.stats = stats;
    },
    setStatsByGroup(state, stats){
      state.statsGroup = stats;
    },
    setSeeds(state, seeds) {
      state.seeds = seeds;
    },
    setStatFilter(state, filters) {
      state.stats_filters = filters;
    },
    setDatabase(state, databases) {
      state.databases = databases;
    },
    setUsersByCountry(state, users) {
      state.usersByCountry = users;
    },
    setOwnersByCountry(state, owners) {
      state.ownersByCountry = owners;
    },
    setCountryByUser(state, countriesUser) {
      state.countriesByUser = countriesUser;
    },
    setAllDatabases(state, databases) {
      state.allDatabases = databases;
    },
    setDatabaseByCountry(state, newDatabases) {
      state.databasesByCountry = newDatabases;
    },
    setTagsByCountry(state, tags) {
      state.tagsByCountry = tags;
    },
    setPlatformsByCountry(state, platforms) {
      state.platformsByCountry = platforms;
    },
    setAgenciesByCountry(state, agencies) {
      state.agenciesByCountry = agencies;
    },
    setSendersByCountry(state, senders) {
      state.sendersByCountry = senders;
    },
    setTargetsByCountry(state, targets) {
      state.targetsByCountry = targets;
    },
    setTemplatesByCountry(state, campaign) {
      state.templatesByCountry = campaign;
    },
    setFilter(state, {key, value}) {
      if(value === ''){
        delete state.filters[key]
      }else{
        state.filters[key] = value;
      }
    },
    setHeaderFooter(state, { headers, footers }) {
      state.headers_items = headers;
      state.footers_items = footers;
    },
    addHeaderFooter(state, item) {
      if (item.type === 'header') {
        state.headers_items.push(item);
      } else {
        state.footers_items.push(item);
      }
    },
    updateHeaderFooter(state, item) {
      const items = item.type === 'header' ? state.headers_items : state.footers_items;
      const index = items.findIndex(i => i.id === item.id);
      if (index !== -1) {
        items[index] = item;
      }
    },

    getCurrencies(state) {
      ApiService.get("currencies/list")
        .then((response) => {
          const currencies = response.data.data;
          state.currencies = currencies.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    getPrefills(state) {
      ApiService.get("tvfprefills")
      .then((response) => {
        const prefills = response.data.data;
        state.tvfPrefills = prefills.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
        Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    getCompanies(state) {
      ApiService.get("companies")
      .then((response) => {
        const companies = response.data.data;
        state.companies = companies.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
        Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    getOwners(state) {
      ApiService.get("owners")
      .then((response) => {
        const owners = response.data.data;
        state.owners = owners.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
        Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    getDomains(state) {
      ApiService.get("domains/list")
      .then((response) => {
        const domains = response.data.data;
        state.domains = domains.sort(function (a, b) {
          if (a.domain.toLowerCase() > b.domain.toLowerCase()) {
            return 1;
          }
          if (a.domain.toLowerCase() < b.domain.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
        Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },    

    getRoles(state) {
      ApiService.get("roles/list")
      .then((response) => {
        const roles = response.data.data;
        state.roles = roles.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
        Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    async addUser(state, { username, first_name, last_name, email, company_id, role, database }) {
      this.newUser = {
        username: username,
        first_name: first_name,
        last_name: last_name,
        email: email,
        company_id: company_id,
        role: role,
        databases: database
      }

      await ApiService.post("users", this.newUser)
        .then(response => {
          state.addDialog = false
          state.users.push(this.newUser)
          commit('getUsers')
        })
        .catch(error => {
          if (typeof error.response.data === 'object') {
            state.errors = []
            state.errors.push(error.response.data.errors)
          } 
        });
      },

      SET_SNACKBAR(state, snackbar) {
        state.snackbars = state.snackbars.concat(snackbar);        
      }
  },

  actions: {
    setSnackbar({commit}, snackbar) {
      snackbar.showing = true;
      snackbar.color = snackbar.color
      snackbar.timeout = snackbar.timeout
      commit('SET_SNACKBAR', snackbar)
    },

    setLoadingAction({ commit }, loading) {
      commit('setLoading', loading);
    },
    setLoadingAction2({ commit }, loading) {
      commit('setLoading2', loading);
    },

    getUsers({ commit }) {
      commit('getUsers')
    },

    getTags({ commit }) {
      commit('getTags')
    },

    getPaymodels({ commit }) {
      commit('getPaymodels')
    },

    getDomains({ commit }) {
      commit('getDomains')
    },

    getPrefills({ commit }) {
      commit('getPrefills')
    },

    getPlatforms({ commit }) {
      commit('getPlatforms')
    },

    getCountries({ commit }) {
      commit('getCountries')
    },

    getOwners({ commit }) {
      commit('getOwners')
    },

    async getCountriesDB({ commit, state }) {
      await ApiService.get(
        "campaign/getDBCountriesByUser/" + state.auth.user.id
      )
      .then((response) => {
        const countries = response.data.countries;
        const sortedCountries = countries.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    
        commit('setCountryByUser', sortedCountries);
    
        const databases = response.data.databases;
        // Guarda una copia completa de las bases de datos en allDatabases
        commit('setAllDatabases', databases);

        const sortedDatabases = databases.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    
        const auxList = [];
        sortedDatabases.forEach((db, index) => {
          auxList[index] = Object.assign({}, db);
        });
    
        commit('setDatabaseByCountry', auxList);
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },
    
    getDBByCountry({ commit, state }, countryId) {
      let newDatabaseList;

      if (countryId) {
        newDatabaseList = state.allDatabases.filter(
          (database) => database.country_id === countryId
        );
      } else {
        newDatabaseList = [...state.allDatabases];
      }

      commit('setDatabaseByCountry', newDatabaseList);
    },

    async getUsersByCountry({ commit }, countryId) {
      await ApiService.post("users/getUsersByUserCountry", {
        idCountry: countryId
      })
      .then((response) => {
        const users = response.data.data;
  
        const sortedUsers = users.sort(function (a, b) {
          if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
            return -1;
          }
          if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        const auxList = [];
        sortedUsers.forEach((db, index) => {
          auxList[index] = Object.assign({}, db);
        });
  
        commit('setUsersByCountry', auxList);
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },

    async getOwnersByCountry({ commit }, countryId) {
      await ApiService.post("owners/getOwnersByUserCountry", {
        idCountry: countryId
      })
      .then((response) => {
        const owners = response.data.data;
  
        const sortedOwners = owners.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        const auxList = [];
        sortedOwners.forEach((db, index) => {
          auxList[index] = Object.assign({}, db);
        });
  
        commit('setOwnersByCountry', auxList);
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },

    async getTagsByCountry({ commit }, countryId) {
      await ApiService.post("tags/getTagsByUserCountry", {
        idCountry: countryId
      })
      .then((response) => {
        const tags = response.data.data;
  
        const sortedTags = tags.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        const auxList = [];
        sortedTags.forEach((db, index) => {
          auxList[index] = Object.assign({}, db);
        });
  
        commit('setTagsByCountry', auxList);
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },

    async getPlatformsByCountry({ commit }, countryId) {
      await ApiService.post("platforms/getPlatformByUserCountry", {
        idCountry: countryId
      })
      .then((response) => {
        const platforms = response.data.data;
  
        const sortedPlatforms = platforms.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        const auxList = [];
        sortedPlatforms.forEach((db, index) => {
          auxList[index] = Object.assign({}, db);
        });
  
        commit('setPlatformsByCountry', auxList);
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },

    async getAgenciesUser({ commit }, countryId) {
      await ApiService.post("agencies/getAgenciesByUserCountry", {
        idCountry: countryId
      })
        .then((response) => {
          const agencies = response.data.data;
    
          const sortedAgencies = agencies.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          const auxList = [];
          sortedAgencies.forEach((db, index) => {
            auxList[index] = Object.assign({}, db);
          });
    
          commit('setAgenciesByCountry', auxList);
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
            .flat()
            .forEach((error) => {
              this.$store.dispatch("setSnackbar", {
                color: "tvf_secondary",
                text: error.message,
                timeout: 3000,
              });
            });
          }
        });
    },

    async getSendersByCountry({ commit }, countryId) {
      await ApiService.post("creas/getSendersByUserCountry", {
        idCountry: countryId
      })
        .then((response) => {
          const creas = response.data.data;
    
          const sortedCreas = creas.sort(function (a, b) {
            if (a.from_name.toLowerCase() > b.from_name.toLowerCase()) {
              return -1;
            }
            if (a.from_name.toLowerCase() > b.from_name.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          const auxList = [];
          sortedCreas.forEach((db, index) => {
            auxList[index] = Object.assign({}, db);
          });
    
          commit('setSendersByCountry', auxList);
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
            .flat()
            .forEach((error) => {
              this.$store.dispatch("setSnackbar", {
                color: "tvf_secondary",
                text: error.message,
                timeout: 3000,
              });
            });
          }
        });
    },

    async getTargetsByCountry({ commit }, countryId) {
      await ApiService.post("sendouts/getTargetsByUserCountry", {
        idCountry: countryId
      })
        .then((response) => {
          const target = response.data.data;
    
          commit('setTargetsByCountry', target);
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
            .flat()
            .forEach((error) => {
              this.$store.dispatch("setSnackbar", {
                color: "tvf_secondary",
                text: error.message,
                timeout: 3000,
              });
            });
          }
        });
    },

    async getTemplatesByCountry({ commit }, countryId) {
      await ApiService.post("templates/getTemplatesByUserCountry", {
        idCountry: countryId
      })
        .then((response) => {
          const templates = response.data.data;
    
          const sortedTemplates = templates.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          const auxList = [];
          sortedTemplates.forEach((db, index) => {
            auxList[index] = Object.assign({}, db);
          });
    
          commit('setTemplatesByCountry', auxList);
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
            .flat()
            .forEach((error) => {
              this.$store.dispatch("setSnackbar", {
                color: "tvf_secondary",
                text: error.message,
                timeout: 3000,
              });
            });
          }
        });
    },

    getAgencies({ commit }) {
      commit('setLoading', true);
      ApiService.get("agencies/list")
      .then((response) => {
        const agencies = response.data.data;
        const sortedAgencies = agencies.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        commit('setLoading', false);
        commit('setAgency', sortedAgencies);
      })
      .catch((error) => {
        commit('setLoading', false);
        if (typeof error.response.data === "object") {
        Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    async getDatabases({ commit }) {
      commit('setLoading', true);
      await ApiService.get("databases/list")
      .then((response) => {
        const databases = response.data.data;
        const sortedDatabases = databases.sort(function (a, b) {
          if (a.id > b.id) {
            return -1;
          }
          if (a.id < b.id) {
            return 1;
          }
          // a must be equal to b
          return 0;
        });
        sortedDatabases.forEach((element, index) => {
          if(element.deleted_at !== null) {
            sortedDatabases[index].switch_status = false
          } else {
            sortedDatabases[index].switch_status = true
          }
        })
        commit('setLoading', false);
        commit('setDatabase', sortedDatabases);
      })
      .catch((error) => {
        commit('setLoading', false);
        if (typeof error.response.data === "object") {
        Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    getActiveDatabases({ commit }) {
      ApiService.get("databases/list/actives")
      .then((response) => {
        const databases = response.data.data.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
        commit('setDatabase', databases);
      })
      .catch((error) => {
        commit('setLoading', false);
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },

    getInactiveDatabases({ commit }) {
      ApiService.get("databases/list/inactives")
      .then((response) => {
        const databases = response.data.data.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
        commit('setDatabase', databases);
      })
      .catch((error) => {
        commit('setLoading', false);
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },

    filterDatabases({ state, commit }) {
      ApiService.post("databases/filter", state.filters)
      .then((response) => {
        const databases = response.data.data.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
        commit('setDatabase', databases);
      })
      .catch((error) => {
        commit('setLoading', false);
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },

    fetchHeadFootItems({ commit }, dbId) {
      ApiService.get("hf/listByDb/" + dbId)
      .then((response) => {
        const headers_items = [];
        const footers_items = [];
        response.data.data.forEach(element => {
          if (element.type === 'header') {
            headers_items.push(element);
          } else {
            footers_items.push(element);
          }
        });

        commit('setHeaderFooter', { headers: headers_items, footers: footers_items });
      })
      .catch((error) => {
        commit('setLoading', false);
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },

    saveCode({ commit }, item) {
      console.log(item)
      ApiService.post("hf", item)
      .then((response) => {
        console.log(response)
        if (item.type === 'header') {
          state.headers_items.push(item);
        } else {
          state.footers_items.push(item);
        }
/*         commit('addHeaderFooter', response.data.data);
 */        this.$store.dispatch('setSnackbar', {
          color: 'success',
          text: this.hf_modal.type.capitalize() + ' has been created!',
          timeout: 3000
        })
      })
      .catch((error) => {
        if (typeof error.response.data === 'object') {
          Object.values(error.response.data.errors).flat().forEach( error => {
          this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3000
          })       
        })}
      });
    },

    updateCode({ commit }, { id, item }) {
      ApiService.put("hf/" + id, item)
      .then((response) => {
        commit('updateHeaderFooter', response.data);
        this.$store.dispatch('setSnackbar', {
          color: 'success',
          text: 'Header or footer added to "' + this.hf_modal.name + '"',
          timeout: 3000
        })
      })
      .catch((error) => {
        if (typeof error.response.data === 'object') {
          Object.values(error.response.data.errors).flat().forEach( error => {
          this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3000
          })       
        })}
      });
    },

    async getHTML({ commit }) {
      commit('setLoading', true);
      if (auth.state.user && auth.state.user.roles && auth.state.user.roles[0].name === 'designer') {
        const statusPriority = {
          "In Progress": 1,
          "Refused": 2,
          "Draft": 3,
          "Kit Ok": 4,
        };
    
        try {
          const response = await ApiService.get("templates/list");
          const templates = response.data.data;
          const sortedTemplates = templates.sort((a, b) => {
            const statusComparison = statusPriority[a.status.name] - statusPriority[b.status.name];
    
            if (statusComparison !== 0) {
              return statusComparison;
            } else {
              if (statusPriority[a.status.name] === 2) {
                const rejectionDateA = new Date(a.refused_at);
                const rejectionDateB = new Date(b.refused_at);
                return rejectionDateB - rejectionDateA;
              } else {
                const creationDateA = new Date(a.created_at);
                const creationDateB = new Date(b.created_at);
                return creationDateB - creationDateA;
              }
            }
          });
          commit('setList', sortedTemplates);
          commit('setLoading', false);
        } catch (error) {
          commit('setLoading', false);
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        }
      } else {
        try {
          const response = await ApiService.get("templates/list");
          const templates = response.data.data;
          const sortedTemplates = templates.sort((a, b) => {
            if (a.created_at_format > b.created_at_format) {
              return -1;
            }
            if (a.created_at_format < b.created_at_format) {
              return 1;
            }
            // a must be equal to b
            return 0;
                
          });
          commit('setList', sortedTemplates);
          commit('setLoading', false);
        } catch (error) {
          commit('setLoading', false);
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        }
      }
        
    },

    async getSendouts({ commit }) {
      commit('setLoading', true);

      await ApiService.get("sendouts/list")
      .then((response) => {
        commit('setLoading', false);
        commit('setSendout', response.data.data);
      })
      .catch((error) => {
        commit('setLoading', false);
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },

    async getStats({ commit }, clauses) {
      commit('setLoading', true);

      await ApiService.post("sendouts/getStatsSendouts", {
        clauses: clauses
      })
      .then((response) => {
        const sendouts = response.data.data;
          const sortedSendouts = sendouts.sort((a, b) => {
            if (a.send_date > b.send_date) {
              return -1;
            }
            if (a.send_date < b.send_date) {
              return 1;
            }
            // a must be equal to b
            return 0;
                
          });
        commit('setStats', sortedSendouts);
        commit('setLoading', false);

      })
      .catch((error) => {
        commit('setLoading', false);
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },

    async getStatsByGroup({ commit }, clauses) {
      commit('setLoading2', true);
      await ApiService.post("sendouts/getStatsSendouts", {
        clauses: clauses
      })
      .then((response) => {
        commit('setLoading2', false);
        const statsGroup = response.data.data;
         
        commit('setStatsByGroup', statsGroup);
      })
      .catch((error) => {
        commit('setLoading2', false);
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },

    async getStatsFilters({ commit, state }) {
      commit('setLoading', true);

      await ApiService.get("filters/getFilterByUser/" + state.auth.user.id)
      .then((response) => {
        commit('setLoading', false);
        commit('setStatFilter', response.data.data);
      })
      .catch((error) => {
        commit('setLoading', false);
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },

    async getSeeds({ commit }, state) {
      commit('setLoading', true);

      await ApiService.get("seeds/list")
      .then((response) => {
        commit('setLoading', false);
        let data = response.data.data;  
        data = data.map(item => {
          const emails = JSON.parse(item.emails);
          let status = item.deleted_at !== null ? false : true;
          return {
            ...item,
            emails,
            emailCount: emails.length,
            status,
          };
        });
/* 
        // Ordenar por nombre
        data.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        }); */
        commit('setSeeds', data);
    
      })
      .catch((error) => {
        commit('setLoading', false);
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },

    getCompanies({ commit }) {
      commit('getCompanies')
    },

    getCurrencies({ commit }) {
      commit('getCurrencies')
    },

    getSources({ commit }) {
      commit('getSources')
    },

    getRoles({ commit }) {
      commit('getRoles')
    },

    addUser({ commit }, { username, first_name, last_name, email, company_id, role, database }) {
      commit('addUser', { username, first_name, last_name, email, company_id, role, database })
    },
  },
  
})
