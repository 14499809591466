import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const theme = {
  primary: '#666666',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  other: '#ffffff',
  info: '#00CAE3',
  success: '#E2FBE9',
  error: '#EF5350',
  tvf_color: '#F85182',
  tvf_secondary: '#FFDFDF',
  btn_secondary: '#BDBDBD',
  requested_kit: '#C9ECF7',
  in_progress: '#FFC69D',
  kit_ok: '#AADD88',
  pending: '#E3C6ED',
  validated: '#A3C1EE',
  refused: '#FFA9A9',
  scheduled: '#E5E5E5',
  sending: '#FF749D',
  finished: '#666666',
  disabled_item: '#BDBDBD'
}

export default new Vuetify({
    icons: {
      iconfont: 'mdi'
    },
    theme: {
      themes: {
        dark: theme,
        light: theme,
      },
    },
});
