import axios from "axios";
import ApiService from "./api.service";
import store from "../store";


const ENDPOINT_PATH = "https://reqres.in/api/";

export default {

    getUserLogged() {
        JSON.parse(localStorage.getItem("userLogged"))
    },
    deleteUserLogged() {
        localStorage.clear()
    },

};