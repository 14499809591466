import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login'
import Forgot_Password from '../views/ForgotPassword'
import store from "../store";
import { TokenService } from '@/services/storage.service'


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: Forgot_Password,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/management',
    name: 'management',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "management" */ '../views/Management.vue'),
    children: [
      {
        path: '/management/users',
        name: 'users',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Users.vue'),
      },
      {
        path: '/management/companies',
        name: 'companies',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Companies.vue'),
      },
      {
        path: '/management/platforms',
        name: 'platforms',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Platforms.vue'),
      },
      {
        path: '/management/databases',
        name: 'databases',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Databases.vue'),
      },
      {
        path: '/management/owners',
        name: 'owners',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Owners.vue'),
      },
      {
        path: '/management/domains',
        name: 'domains',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Domains.vue'),
      },
      {
        path: '/management/agencies',
        name: 'agencies',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Agencies.vue'),
      },
      {
        path: '/management/countries',
        name: 'countries',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Countries.vue'),
      },
      {
        path: '/management/test',
        name: 'test',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/test.vue'),
      },
      {
        path: '/management/roles_permissions',
        name: 'roles_permissions',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Roles&Permissions.vue'),
      },
      {
        path: '/management/seeds',
        name: 'seeds',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Seeds.vue'),
      },
      {
        path: '/management/currencies',
        name: 'currencies',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Currencies.vue'),
      },
      {
        path: '/management/tags',
        name: 'tags',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Tags.vue'),
      },
      {
        path: '/management/paymodels',
        name: 'paymodels',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Paymodels.vue'),
      },
      {
        path: '/management/tvf_prefills',
        name: 'tvf_prefills',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Tvf_prefills.vue'),
      },
    ]
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "campaigns" */ '../views/Campaigns.vue'),
    children: [
      {
        path: '/campaigns/list',
        name: 'list',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/List.vue')
      },
      {
        path: '/campaigns/calendar',
        name: 'calendar',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Calendar.vue')
      },
      {
        path: '/campaigns/stats',
        name: 'stats',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Stats.vue')
      },
      {
        path: '/campaigns/sendouts',
        name: 'sendouts',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "management" */ '../views/Sendout.vue')
      },
    ]
  },
  {
    path: '/invoicing',
    name: 'invoicing',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "invoicing" */ '../views/Invoicing.vue')
  },
  {
    path: '/validation_page',
    name: 'validation',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "invoicing" */ '../views/ValidateSendout.vue')
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  let loggedIn = store.getters.isAuthenticated;
  
  if (!isPublic && !loggedIn) {
    if (TokenService.getToken()) {
      let tokenHasExpired = checkIfTokenHasExpired(TokenService.getToken()); // Suponiendo que tienes una función que verifica la expiración del token.

      if (tokenHasExpired) {
        try {
          await store.dispatch('refreshToken');
          next();
        } catch (error) {
          await store.dispatch('logout');
          next('/login');
        }
      } else {
        store.dispatch('getUserWithToken');
        loggedIn = store.getters.isAuthenticated;
        next();
      }
    } else {
      return next({
        path: '/login',
        query: {redirect: to.fullPath} // Almacena la ruta completa para redireccionar después del inicio de sesión
      });
    }
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }

  next()
}); 

export default router
