import ApiService from './services/api.service'
import App from './App2.vue'
import router from './router'
import store from './store'
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import ToggleButton from 'vue-js-toggle-button'
import '@/assets/css/global.scss'; // Importa tus estilos globales

Vue.directive('preview', {
  bind(el, binding, vnode) {
    el.attachShadow({mode: 'open'})
    el.shadowRoot.innerHTML = binding.value
  },
  update(el, binding, vnode) {
    if (vnode.elm.shadowRoot.innerHTML !== binding.value) {
      el.shadowRoot.innerHTML = binding.value;
    }
  }
})

Vue.filter('formatNumber', function (value) { return new Intl.NumberFormat('es-ES', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  useGrouping: true
}).format(value);});

Vue.directive('can', {
  bind: function (el, binding, vnode) {

    if(binding.value === ''){
      if (!store.state.auth.permissions
        .includes(binding.expression
          .replace(/'/g, "")
            .replace(/"/g, ""))) {
        vnode.elm.style.display = "none";
      }
    } else {
      if (!store.state.auth.permissions
        .includes(binding.value)) {
        vnode.elm.style.display = "none";
      }
    }

  }
})

Vue.use(ToggleButton)

Vue.config.productionTip = false

//Set the base URL of the API
ApiService.init(process.env.VUE_APP_ROOT_API)
ApiService.setHeader()

new Vue({
  router,
  store,
  vuetify,
  data: {
    eventHub: new Vue()
  },  
  render: h => h(App)
}).$mount('#app')

