<template>
    <div class="login">   
      <form
        action
        class="form white"
        @submit.prevent="submit"
      >
        <img 
          src="../assets/LogoTVF.png"
          class="logo pb-4"
          width="175" 
          height="auto" 
        /> 
        <h3 class="text-center font-weight-light mb-8">Reset Password</h3>
        <v-alert
            class="mb-6"
            text
            dense
            color="tvf_color"
            border="left"
        >
            <div>
                <p class="body-2">Enter your <u>Email</u> and instructions will be sent to you!</p>
            </div>
        </v-alert>
        <v-text-field
          v-model="email"
          type="email"
          id="email"
          :rules="emailRules"
          class="body-2"
          placeholder="Email"
          outlined
          dense
          required        
        ></v-text-field>    
        <p v-if="error" class="error-msg error--text body-2">Email or password are incorrect.</p>
        <v-btn
          type="submit"
          class="loginButton mt-2 mb-4 white--text"
          color="tvf_color"
        >
          Reset
        </v-btn>
        <div class="pt-2" align="center">
            <router-link :to="returnLogin.to" class="forgot-pass body-2">
            {{ returnLogin.name }}
            </router-link> 
        </div>
      </form>       
    </div>  
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    name: "Login",
    data: () => ({
      email: "",
      password: "",
      error: false,
      showPass: false,
      forgotPass: {
        name: 'Forgot your password?',
        to: '/forgot_password'
      },
      returnLogin: {
        name: 'Back to Log in',
        to: '/login'
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid Email Address',
      ],
    }),
  
    computed: {
      ...mapGetters('auth', [
        'authenticating',
        'authenticationError',
        'authenticationErrorCode'
      ])
    },
  
    methods: {
      ...mapActions('auth', [
        'login'
      ]),
  /* 
      resetData() {
        this.email = this.password = ""
      }, */
  
      submit() {
        if (this.email != '') {
         
        }
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .login {
    padding: 2rem;
  }
  .logo{
      margin: 1.5rem auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
  }
  .form {
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    min-width: 380px;
    max-width: 100%;
    background: rgba(19, 35, 47, 0.9);
    border-radius: 5px;
    padding: 30px;
    box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.05);
  }
   
  .loginButton {
    cursor: pointer;
  }
    
  </style>