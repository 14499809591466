<template>
  <div>
    <v-container>
      <v-row class="pt-5">
        <v-col
          cols="12"
          xs="7"
          sm="12"
          md="4"
          lg="4"
        >
          <v-sheet
            rounded="lg"
            min-height="250"
            elevation="3"
            class="pt-4"
          >
            <v-row class="quick-access pl-7 pr-7 pt-4">
              <v-col
                cols="4"
                md="4"
                class="pt-1 px-4"
              >
                <v-sheet
                  align="center"
                  elevation="1"
                  rounded="lg"
                  min-height="200"
                  class="pt-15"
                >
                  <v-img
                    width="32"
                    src="@/assets/icons/calendar.png"
                  />
                  <p class="options mt-1">
                    Calendar
                  </p>
                </v-sheet>
              </v-col>
              <v-col
                cols="4"
                md="4"
                class="pt-1 px-4"
              >
                <v-sheet
                  align="center"
                  color="white"
                  elevation="1"
                  rounded="lg"
                  min-height="200"
                  class="pt-15"
                >
                  <v-img
                    width="32"
                    src="@/assets/icons/statistic.png"
                  />
                  <p class="options mt-2">
                    Statistics
                  </p>
                </v-sheet>
              </v-col>
              <v-col
                cols="4"
                md="4"
                class="pt-1 px-4"
              >
                <v-sheet
                  align="center"
                  color="white"
                  elevation="1"
                  rounded="lg"
                  min-height="200"
                  class="pt-15"
                >
                  <v-img
                    width="32"
                    src="@/assets/icons/campaign_list.png"
                  />
                  <p class="options mt-2">
                    Campaign<br> List
                  </p>
                </v-sheet>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="8"
        >
          <v-sheet
            min-height="250"
            rounded="lg"
            elevation="3"
            class="d-flex justify-center"
          >
            <p class="ml-6 pt-8 btn_secondary--text">
              Day 14 of 30: AVG x Day 1000€
            </p>
            <div>
              
            </div>
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="2"
          sm="4"
        >
          <v-sheet
            align="center"
            rounded="lg"
            min-height="250"
            elevation="3"
            class="p-16"
          >            
          </v-sheet>
        </v-col>    
      </v-row>
      <v-row class="pt-5">
        <v-col
          cols="12"
          sm="12"
          md="8"
          lg="8"
        >
          <v-sheet
            rounded="lg"
            max-height="450"
            elevation="4"
          >
            <!-- <div id="chart" class="py-4">
            </div> -->
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
        >
          <v-sheet
            rounded="lg"
            min-height="383"
            elevation="4"
          >
            <p class="ml-9 pt-8">
              NOT INVOICED SENDOUTS
            </p>
            <v-data-table
              :headers="sendouts_headers"
              :items="options.series"
              class="database-list caption px-10 pt-4"
              :items-per-page="-1"
              hide-default-footer
            >
              <v-progress-linear slot="progress" color="tvf_color" indeterminate></v-progress-linear>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row class="pt-5">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="4"
        >
          <v-sheet
            rounded="lg"
            min-height="350"
            elevation="4"
          >
            <p class="ml-9 pt-8">
              TOP DATABASES
            </p>
            <v-data-table
              :headers="db_headers"
              :items="options.series"
              class="database-list caption px-10 pt-4"
              :items-per-page="-1"
              hide-default-footer
            >
              <v-progress-linear slot="progress" color="tvf_color" indeterminate></v-progress-linear>
            </v-data-table>
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="8"
          lg="8"
        >
          <v-sheet
            rounded="lg"
            min-height="350"
            elevation="4"
          >
            <p class="ml-9 pt-8">
              LOW DELIV. DATABASES AND CAMPAIGNS
            </p>
            <v-data-table
              :headers="deliv_headers"
              :items="options.series"
              class="database-list caption px-10 pt-4"
              :items-per-page="-1"
              hide-default-footer
            >
              <v-progress-linear slot="progress" color="tvf_color" indeterminate></v-progress-linear>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/* import ApexCharts from 'apexcharts'
 */
export default {
  data() {
    return {
      sendouts_headers: [
        {
          text: 'NAME',
          align: "start",
          value: "name",
          sortable: true,
        },
        {
          text: 'DATE',
          align: "start",
          value: "type",
          sortable: false,
        },
      ],
      db_headers: [
        {
          text: 'DATABASE',
          align: "start",
          value: "name",
          sortable: true,
        },
        {
          text: 'ECPM',
          align: "center",
          value: "data[0]",
          sortable: false,
        },
      ],
      deliv_headers: [
        {
          text: 'NAME',
          align: "start",
          value: "name",
          sortable: true,
        },
        {
          text: 'DATABASE',
          align: "start",
          value: "type",
          sortable: false,
        },
        {
          text: 'DR',
          align: "center",
          value: "data[1]",
          sortable: false,
        },
      ],
      options :{
        /* chart: {
          height: 350,
          type: "line",
          stacked: false
        }, */
        dataLabels: {
          enabled: false
        },
        colors: ['#FFDFDF', '#F48FB1', '#F85182'],
        series: [
          {
            name: 'Column A',
            type: 'column',
            data: [21.1, 23, 33.1, 34, 44.1, 44.9, 56.5, 58.5]
          },
          {
            name: "Column B",
            type: 'column',
            data: [10, 19, 27, 26, 34, 35, 40, 38]
          },
          {
            name: "Line C",
            type: 'line',
            data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
          },
        ],
        stroke: {
          width: [4, 4, 4]
        },
        plotOptions: {
          bar: {
            columnWidth: "90%"
          }
        },
        xaxis: {
          categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
        },
        yaxis: [
          {
            seriesName: 'Column A',
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: "Columns"
            }
          },
          {
            seriesName: 'Column A',
            show: false
          }, {
            opposite: true,
            seriesName: 'Line C',
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: "Line"
            }
          }
        ],
        tooltip: {
          shared: false,
          intersect: true,
          x: {
            show: false
          }
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40
        }
      }
    }
  },

  /* mounted() {
    var chart = new ApexCharts(document.querySelector("#chart"), this.options);

    chart.render();
  } */
};
</script>

<style scoped>
@font-face {
  font-family: "Circular";
  src: url("~@/assets/fonts/CircularStd-Black.otf") format("otf"),
  url("~@/assets/fonts/CircularStd-BlackItalic.otf") format("ttf"),
  url("~@/assets/fonts/CircularStd-Bold.otf") format("woff");

}

p {
  font-family: Circular;
  font-size: 16px;
  font-weight: bold;
}
.options {
  font-family: Circular;
  font-size: 14px;
  font-weight: bold;
}


</style>