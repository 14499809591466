import axios from 'axios';
import { TokenService } from '../services/storage.service';
import store from '../store';


const ApiService = {
	_401interceptor: null,

	init(baseURL) {
		axios.defaults.baseURL = baseURL;
	},

	setHeader() {
		let token = TokenService.getToken();
		if( token ){
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
		}
		/* axios.defaults.headers.common[
			'Authorization'
		] = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLmRldi50aGV2YWx1ZWZhY3RvcnkuaXRcL2FwaVwvYXV0aGVudGljYXRlIiwiaWF0IjoxNjY1NzQ3Njg3LCJleHAiOjE2OTcyODM2ODcsIm5iZiI6MTY2NTc0NzY4NywianRpIjoiNGdSVldWSmJ3YnRxZU8yeiIsInN1YiI6MSwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.2qMaK8inOPxvq-ds6CPpxbcvidSG6wbRpzuBiflIKTw`; */
        /*server*/
        /* `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9udWV2b2xhcmF2ZWwudGVzdFwvYXBpXC9hdXRoZW50aWNhdGUiLCJpYXQiOjE2NjM1OTkyODgsImV4cCI6MTY5NTEzNTI4OCwibmJmIjoxNjYzNTk5Mjg4LCJqdGkiOiJJNGhTVnBhbEF3VXV3NFg4Iiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.a1v9-XRKx3-mj1OJVtYnHN-hy4fb6gLa1YteFgBcMdQ`;  */
        /*local*/
	},

	removeHeader() {
		axios.defaults.headers.common = {};
	},

	get(resource) {
		return axios.get(resource);
	},

	post(resource, data) {
		return axios.post(resource, data);
	},

	put(resource, data) {
		return axios.put(resource, data);
	},

	patch(resource, data) {
		return axios.patch(resource, data);
	},

	delete(resource) {
		return axios.delete(resource);
	},

	/**
	 * Perform a custom Axios request.
	 *
	 * data is an object containing the following properties:
	 *  - method
	 *  - url
	 *  - data ... request payload
	 *  - auth (optional)
	 *    - username
	 *    - password
	 **/

	customRequest(data) {
		return axios(data);
	},
	// Stores the 401 interceptor position so that it can be later ejected when needed

	mount401Interceptor() {
		this._401interceptor = axios.interceptors.response.use(
			(response) => {
				return response;
			},
			async (error) => {
				if (error.request.status == 401) {
					if (error.config.url.includes('/o/token/')) {
						// Refresh token has failed. Logout the user
						store.dispatch('auth/logout');
						throw error;
					} else {
						// Refresh the access token
						try {
							await store.dispatch('auth/refreshToken');
							// Retry the original request
							return this.customRequest({
								method: error.config.method,
								url: error.config.url,
								data: error.config.data,
							});
						} catch (e) {
							// Refresh has failed - reject the original request
							throw error;
						}
					}
				}

				// If error was not 401 just reject as is
				throw error;
			}
		);
	},

	unmount401Interceptor() {
		// Eject the interceptor
		axios.interceptors.response.eject(this._401interceptor);
	},
};

export default ApiService;
