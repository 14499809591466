<template>
  <div class="login">   
    <form
      action
      class="form white"
      @submit.prevent="submit"
    >
      <img 
        src="../assets/LogoTVF.png"
        class="logo pb-4"
        width="175" 
        height="auto" 
      /> 
      <v-text-field
        v-model="email"
        type="email"
        id="email"
        class="caption"
        placeholder="Email"
        :rules="emailRules"
        outlined
        dense
        required        
      ></v-text-field>    
      <v-text-field
        :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
        :type="showPass ? 'text' : 'password'"
        v-model="password"
        id="password"
        class="caption"
        placeholder="Password"
        outlined
        dense
        @click:append="showPass = !showPass"
      ></v-text-field>
      <p v-if="error" class="error-msg error--text body-2">Email or password are incorrect.</p>
      <v-btn
        type="submit"
        class="loginButton mt-2 white--text"
        color="tvf_color"
      >
        Log in
      </v-btn>
      <div class="pt-6" align="center">
        <router-link :to="forgotPass.to" class="forgot-pass body-2">
          <v-icon
            small
          >
            mdi-lock
          </v-icon>
          {{ forgotPass.name }}
        </router-link> 
      </div>
    </form>       
  </div>  
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  data: () => ({
    email: "",
    password: "",
    error: false,
    showPass: false,
    forgotPass: {
      name: 'Forgot your password?',
      to: '/forgot_password'
    },
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid Email Address',
    ],
  }),

  methods: {
    ...mapActions(['login']),

    redirect() {
      this.$router.push("/");
    },

    resetData() {
      this.email = this.password = ""
    },

    async submit() {
      try {
        await this.login({
          email: this.email, 
          password: this.password
        });        
        this.error = false;
        this.redirect();

      } catch (error) {
        this.error = true;
      } 
    }
  },
};
</script>

<style lang="scss" scoped>
.login {
  padding: 2rem;
}
.logo{
    margin: 1.5rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.form {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 380px;
  max-width: 100%;
  background: rgba(19, 35, 47, 0.9);
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.05);
}
.forgot-pass{
  text-decoration: none;
}

.loginButton {
  cursor: pointer;
}
.error-msg {
  text-align: center;
}

</style>