import ApiService from "../../services/api.service";
import AuthService from "../../services/auth.service";
import axios from "axios";
import { TokenService } from '@/services/storage.service'


const state = {
  user: null,
  accessToken: null,
  permissions: null,
  errors: [],
  snackbars: [],
};
  
const getters = {
  isAuthenticated: (state) => !!state.accessToken,    
  StateUser: (state) => state.user,
};

const mutations = {

  setUser(state, user) {
    state.user = user
  },

  logout(state){
    state.user = null
    state.accessToken = null
    state.permissions = null
    TokenService.removeToken()
    TokenService.removeRefreshToken()
  },

  SET_SNACKBAR(state, snackbar) {
    state.snackbars = state.snackbars.concat(snackbar);        
  },  

  SET_TOKEN(state, newToken) {
    state.accessToken = newToken;
    // también podrías querer guardar el token en localStorage aquí
    TokenService.saveToken(newToken);
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

const actions = {

    async login({ commit, state }, user) {
      
      await ApiService.post('/users/session', user).then((response) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
        state.accessToken = response.data.token
        this.state.auth.user = response.data.user
        TokenService.saveToken(response.data.token)
        TokenService.saveRefreshToken(response.data.token)

        ApiService.get("/get-permissions").then((response) => {
          this.state.auth.permissions = response.data.permissions
        })    
      })    
      commit('setUser', this.state.auth.user)
    },

    async getUserWithToken({commit, state}) {

      await ApiService.get('/users/getUserWithToken').then((response) => {
        state.accessToken = TokenService.getToken()
        state.user = response.data.user

      })
      await ApiService.get("/get-permissions").then((response) => {
        this.state.auth.permissions = response.data.permissions
      }) 
      commit('setUser', state.user)
    },

    async refreshToken({ commit }) {
      try {
        const newAccessToken = await AuthService.refreshToken();
        commit('SET_TOKEN', newAccessToken);
        return newAccessToken;
      } catch (error) {
        commit('SET_ERROR', error);
      }
    },

    async LogOut({commit}){
      let user = null
      commit('logout', user)
      localStorage.removeItem('vuex')
    },

};

  
export default {
    state,
    getters,
    actions,
    mutations
};

